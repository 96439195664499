@import "./variable.scss";
.flex-center{
  @apply flex justify-center items-center
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.title {
  overflow: hidden;
  position: relative;
}

.title .title-item {
  display: block;
  position: absolute;
  width: 100%;
  top: 50%;
  transform: translateY(-50%);
  animation: marquee 8s linear infinite forwards;
}

@keyframes marquee {
  0% { left: 0; }
  100% { left: -100%; }
}

// ========== button ant design ===================
.ant-btn:hover{
  color: $primary !important;
  border-color: $primary !important;
}

.ant-btn-primary {
  background: $primary !important;
  text-shadow: none !important;
  box-shadow: none !important;
  border:none !important;
}

.ant-btn-primary:hover {
  border:none !important;
  color: white !important;
}


// ==================== ant tabs ============================
.ant-tabs-tab.ant-tabs-tab-active {
  border-bottom: 2px solid #0eaf8f !important;
  z-index: 2;
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: $primary !important;
}
.ant-tabs-tab:hover {
  color: #0eaf8f !important;
}
.ant-tabs > .ant-tabs-nav,
.ant-tabs > div > .ant-tabs-nav {
  margin-bottom: 0px !important;
}
// ------ end ant tabs -----


//--------badge -------------

// ----------- checkbox ------------
.ant-checkbox{
}

.ant-checkbox-checked .ant-checkbox-inner {
  background: $primary !important;
  border-color: $primary !important;
}

.ant-checkbox:hover{
  border-color: $primary !important;
}
// ----------- checkbox ------------
