@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
@import "~ng-zorro-antd/style/index.min.css";
@import "~ng-zorro-antd/button/style/index.min.css";
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import "./assets/scss/reset.scss";
@import "~quill/dist/quill.snow.css";
@import "swiper/css";
@import "swiper/css/navigation";
@import "swiper/css/pagination";
@import "./assets/scss/custom.scss";
@import "./assets/scss/variable.scss";
@import "./assets/scss/quill.scss";

// ========== color variable =======================
$primary: #0eaf8f;
$gray-color: #f9f9f9;
$disable: #727276;
$gray-border: #a5a5a9;
$primary-bg: #e8fdf9;
$danger: #ef4446;
// ========== end - color variable =================

body,
html {
  font-family: "Quicksand", sans-serif !important;
  scroll-behavior: smooth;
}

p {
  margin-bottom: 0px !important;
}

button {
  border-radius: 2px;
  @apply py-1;
}

.color {
  &-success {
    @apply text-primary;
  }
  &-error {
    @apply text-red-text;
  }
  &-info {
    @apply text-sky-600;
  }
  &-warning {
    @apply text-orange-500;
  }
}

:host ::ng-deep .ant-switch {
  background-color: #727276 !important;
}

.ant-switch-checked {
  background-color: $primary !important;
}

.button {
  @apply font-semibold;
  &-default {
    @apply bg-white border text-black hover:bg-gray-50;
  }
  &-success {
    @apply bg-primary hover:bg-primary/90 duration-200 border border-primary text-white;
  }
  &-error {
    @apply bg-red-text hover:bg-red-text/90 duration-200 border border-red-text text-white;
  }
  &-info {
    @apply bg-sky-600 hover:bg-sky-600/90 duration-200 border border-sky-600 text-white;
  }
  &-warning {
    @apply bg-orange-500 hover:bg-orange-500/90 duration-200 border border-orange-500 text-white;
  }
  &-disable {
    @apply bg-disable hover:bg-disable/90 duration-200 border border-disable text-white;
  }
  &-outline-success {
    @apply border border-primary text-primary;
  }
  &-outline-error {
    @apply border border-red-text text-red-text;
  }
  &-outline-info {
    @apply border border-sky-600 text-sky-600;
  }
  &-outline-warning {
    @apply border border-orange-500 text-orange-500;
  }
  &-outline-disable {
    @apply border border-disable text-disable;
  }
  &-outline-default {
    @apply border border-[#727276];
  }
}

// ================== card ===============
// .card {
//   @apply border border-solid border-gray-border p-3 rounded-sm;
// }
// ===============end --- card ==========

// ============= image =============
.ant-image-preview-img {
  display: inline-block !important; //will make the image to center, tested okay when i change in console element
}

// ============== radio ===============
::ng-deep [nz-radio] {
  display: block;
  height: 32px;
  line-height: 32px;
}

// ========== message notification ==============
.ant-message-custom-content {
  display: flex;
  align-items: center !important;
}

// ========= qr-modal ==========
.qr-modal .ant-modal-content {
  border-radius: 5px;
}

// ========= end qr-modal ==========
// ========= cursor button =========
button:disabled {
  cursor: not-allowed;
  pointer-events: all !important;
}
// ========= end cursor button =========
// ========= responsive width modal =======
@media (max-width: 639px) {
  .responsive-modal {
    width: 100% !important;
  }
}
@media (min-width: 640px) {
  .responsive-modal {
    width: $width-responsive-large !important;
  }
}
.loading-modal {
  background-color: rgba(
    0,
    0,
    0,
    0.9
  ); /* Màu đen trong suốt với độ mờ là 50% */
  opacity: 1; /* Độ trong suốt là 100% */
}
.loading-modal .ant-modal-content {
  background-color: rgba(0, 0, 0, 0); /* Màu đen trong suốt với độ mờ là 50% */
  opacity: 1; /* Độ trong suốt là 100% */
}
// ========= end responsive width modal =======

.card {
  background: white;
  @apply p-3;
  .card-header {
    @apply font-bold text-primary;
  }
}

// ============ nztab =============
.ant-tabs-tab + .ant-tabs-tab {
  margin: 0 0 0 16px !important;
}

.ant-tabs-tab-btn {
  font-size: 15px !important;
}

em {
  font-style: italic !important;
}